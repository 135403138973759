import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import img from '../images/newgif.gif'
const DesktopMenu = () => {

return (
    <>
<Nav>


            <Li>
              <Link to="/about"><NavItem>ABOUT</NavItem></Link>
            </Li>
            <Li>
              <Link to="/self-hypnosis"><NavItem>SELF HYPNOSIS</NavItem></Link>
            </Li>
            <Li>
              <Link to="/hypnotherapy"><NavItem>HYPNOTHERAPY</NavItem></Link>
            </Li>
            <Li>
              <Link to="/group-hypnosis"><NavItem>GROUP HYPNOSIS</NavItem></Link>
            </Li>
         
</Nav>
{/* <Img src= {img}/> */}
</>
);

}


const Img = styled.img`
max-width: 300px;
margin-left: 20px;
position: absolute;
z-index: 10000;
top: 400px;`

const Nav = styled.div`
display: none;

    @media (min-width: 1000px) {


        color: #ff0000;
        width: 300px;
        min-width: 300px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 1%;
    }

`
const NavItem = styled.h1`
color: #ff0000;
text-align: center;
font-size: 25px !important;
margin-top: 2%;
margin-bottom: 2%;
-webkit-text-stroke: .1px black;
`
const Li = styled.button`

list-style: none;
margin:2%;
border-radius: 1rem;

padding: 2%;
box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.7);
cursor: pointer;
  transition: background-color 0.3s ease, transform 0.1s ease;

  &:hover {
    background-color: #ffc9eb;
  }

  &:active {
    background-color: #ffc9eb;
    transform: scale(0.95);
  }
`

export default DesktopMenu;