import React, {useState} from 'react';
import styled from 'styled-components';
import Footer from './Footer';
import Menu from './Menu';
import BookingButton from './BookingButton';
import Navbar from './Navbar';
import DesktopMenu from './DesktopMenu';
import img from '../images/gif3.gif'


const Hypnotherapy = () => {
    const [openSections, setOpenSections] = useState({});

    const toggleSection = (section) => {
      setOpenSections((prevSections) => ({
        ...prevSections,
        [section]: !prevSections[section],
      }));
    };
  
    return (
        <>
       
        <Navbar/>
<DeskWrap>

       <DesktopMenu/>
        <Wrapper>
        <Body>
    <h3>Book an appointment or a free consultation.</h3>
</Body>
        <BookWrap> <BookingButton/> <Img src= {img}/> </BookWrap>
<Body>
    <p>90 minute 1:1 hypnotherapy sessions can take place virtually or in person in Julia’s studio in center Brussels. </p>
    <p>The session includes a consultation and the hypnotic experience lasts between 45 and 60 minutes. </p>
        <p>Following the hypnosis we take some time to integrate and discuss the experience. 
        After the session clients generally feel wonderful and prepared to move on with their day or go home to bed if they wish. </p>
</Body>



        <Body>
        <h1 >FAQ</h1>
    <H1 onClick={() => toggleSection('faq')}>What should I expect from the hypnotic experience?</H1>
  <Drop isOpen={openSections.faq}>
    <p>During a hypnotherapy session, you can expect to enter a state of deep relaxation where your mind becomes highly focused and receptive to positive suggestions. Contrary to popular myths, you will remain fully aware and in control throughout the session. The experience often involves guided visualizations, calming imagery, and verbal cues that help you access your subconscious mind. Many people describe the sensation as deeply relaxing, similar to daydreaming or meditation. The goal is to create a state where you can effectively address and transform specific behaviors, thoughts, or emotions.</p>
  </Drop>
    
    <H1 onClick={() => toggleSection('issues')}>What issues does hypnotherapy work for?</H1>
  <Drop isOpen={openSections.issues}>
    
    <p>Hypnotherapy can be a powerful tool for addressing a wide range of issues, including but not limited to:</p>
    <ul>
      <li><Strong>Stress and Anxiety:</Strong> Reducing feelings of anxiety and promoting a sense of calm.</li>
      <li><Strong>Habit Control:</Strong> Breaking habits such as smoking, nail-biting, or overeating.</li>
      <li><Strong>Sleep Issues:</Strong> Improving sleep quality and addressing insomnia.</li>
      <li><Strong>Self-Confidence:</Strong> Enhancing self-esteem and self-worth.</li>
      <li><Strong>Pain Management:</Strong> Alleviating chronic pain and discomfort.</li>
      <li><Strong>Phobias and Fears:</Strong> Overcoming irrational fears and phobias.</li>
      <li><Strong>Emotional Healing:</Strong> Processing and healing from past traumas and emotional wounds.</li>
      <li><Strong>Performance Enhancement:</Strong> Improving performance in sports, academics, and other areas by increasing focus and reducing performance anxiety.</li>
    </ul>
    <p>Hypnotherapy is tailored to your individual needs, making it a versatile approach for many different challenges.</p>
  </Drop>
    
    <H1 onClick={() => toggleSection('virtual')}>Does virtual hypnosis work?</H1>
  <Drop isOpen={openSections.virtual}>

    <p>Yes, virtual hypnosis is highly effective and offers the same benefits as in-person sessions. With the convenience of modern technology, hypnotherapy can be conducted through video calls, allowing you to experience the process from the comfort of your own home. The key components of hypnosis—such as guided relaxation, visualization, and suggestion—are just as impactful in a virtual setting. Many clients find virtual sessions to be more accessible and just as transformative as in-person meetings, making it a flexible option for those with busy schedules or geographical constraints.</p>
  </Drop>
      </Body>
      </Wrapper>
</DeskWrap>
{/* <Footer/> */}

     </>
    );
    
    }

    const Img = styled.img`
        max-width: 150px;
    margin-left: 20px;
    
    @media (min-width: 500px) {
max-width: 170px;
margin-left: 300px;

}

@media (min-width: 1000px) {
margin-top: 400px;

}
    `

    const DeskWrap = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;

    @media (min-width: 1000px) {
flex-direction: row;

}
`
const Body = styled.div`
   font-family: var(--font-body);
   margin-top: 3%;

   @media (min-width: 1000px) { 
   margin-top: 0%;
   margin-right: 10%;
   max-width: 70%;
   
}
`

 const Drop = styled.div`
  max-height: ${({ isOpen }) => (isOpen ? '1000px' : '0')};
  opacity: ${({ isOpen }) => (isOpen ? '1' : '0')};
  overflow: hidden;
  transition: max-height 0.6s ease, opacity 0.6s ease;
  margin-top: ${({ isOpen }) => (isOpen ? '10px' : '0')};
 `

const H1 = styled.h1`
font-size: 16px;
cursor: pointer;

&:hover {
    color: #ffc9eb;
  }`


const Wrapper=styled.div`
padding: 2%;
display: flex;
padding: 2%;
flex-direction: column;
justify-content: space-evenly;
height: 90vh;

@media (min-width: 500px) { 

  justify-content: flex-start;
  
}

@media (min-width: 1000px) { 
    justify-content: flex-start;
        height: 100%;
        padding: 0%;
}
`

const BookWrap = styled.div`
width: 100%;
display:flex;
align-items: center;
justify-content:flex-start;
padding: 2% 2% 2% 0%;
height: 30px;

@media (min-width: 1000px) { 

}
`
    const Strong = styled.h3`
    font-weight: 600;
    `
    export default Hypnotherapy;