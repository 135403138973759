import React from 'react';
import GumroadFollowForm from './GumroadFollowForm';
import styled from 'styled-components';


const Footer = () => {

    return (
        <>
     <Form>
     <GumroadFollowForm/>
     </Form>
     </>
    );
    
    }

    const Form = styled.div`

    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 25%;
    margin-bottom: 2%;
    padding-top: 15%;

    
@media (min-width: 500px) {

}

@media (min-width: 1000px) {
    margin-top: 2%;
    margin-bottom: 5%;
    padding: 0%;

   
}  `


    
    export default Footer;