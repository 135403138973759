import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Nav>
      <Hamburger isOpen={isOpen} onClick={toggleMenu}>
        <div />
        <div />
        <div />
      </Hamburger>
      <Menu isOpen={isOpen}>
        <li>
          <Link to="/about">
            <NavItem>ABOUT</NavItem>
          </Link>
        </li>
        <li>
          <Link to="/self-hypnosis">
            <NavItem>SELF HYPNOSIS</NavItem>
          </Link>
        </li>
        <li>
          <Link to="/hypnotherapy">
            <NavItem>HYPNOTHERAPY</NavItem>
          </Link>
        </li>
        <li>
          <Link to="/group-hypnosis">
            <NavItem>GROUP HYPNOSIS</NavItem>
          </Link>
        </li>
      </Menu>
    </Nav>
  );
};

const Nav = styled.nav`
  position: relative;

  @media (min-width: 1000px) {
    display: none;

}  
`;



const Hamburger = styled.div`
margin: 5%;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 30px;
  height: 25px;
  background: transparent;
  border: none;
  z-index: 10000;
  div {
    margin: 5%;
    width: 30px;
  z-index: 10000;

    height: 3px;
    background: black;
    border-radius: 5px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;
    &:nth-child(1) {
      transform: ${({ isOpen }) => (isOpen ? "rotate(45deg)" : "rotate(0)")};
    }
    &:nth-child(2) {
      opacity: ${({ isOpen }) => (isOpen ? "0" : "1")};
      transform: ${({ isOpen }) =>
        isOpen ? "translateX(20px)" : "translateX(0)"};
    }
    &:nth-child(3) {
      transform: ${({ isOpen }) => (isOpen ? "rotate(-45deg)" : "rotate(0)")};
    }
  }
`;

const Menu = styled.ul`
  display: ${({ isOpen }) => (isOpen ? "block" : "none")};
    position: fixed;
    top: 50px;
    right: 0;
    background: #FFC9EB;
    list-style: none;
    margin: 0;
    height: fit-content;
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
    border-radius: 5%;
    border: 1px solid black;
    padding-top: 10%;
    padding-bottom: 10%;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.7);

  li {
    margin: 10px 0;
  }
`;

const NavItem = styled.div`
  padding: 10px;
  text-decoration: none;
  color: #ff0000;
text-align: center;
font-size: 25px !important;
margin-top: 2%;
margin-bottom: 2%;
-webkit-text-stroke: .3px black;
  &:hover {
    color: #ffc9eb;
  }

`;

export default Navbar;
