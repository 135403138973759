import styled from 'styled-components';
import { Link } from 'react-router-dom';

import React from 'react';
import Menu from './Menu';
import img from '../images/webgif4.gif'
import Footer from './Footer';
import DesktopMenu from './DesktopMenu';

const Home = () => {

    return (
        <Page>
          <DesktopMenu/>
    <ImgWrap>
    <Img src= {img}/>
    </ImgWrap>
<Menu/>
    </Page>

    );
    
    }

const Page = styled.div`
height:100vh;
display: flex;
flex-direction: column;
justify-content: space-evenly;



@media (min-width: 1000px) {
  flex-direction: row;
  justify-content: flex-start;
    
}

`
    
const Img = styled.img`

max-width: 90%;

@media (min-width: 500px) {
    max-height: 600px;
    
}

`

const ImgWrap = styled.div`
width: 100%;
display:flex;
justify-content: center;
align-items: center;

@media (min-width: 500px) {

}

@media (min-width: 1000px) {
  width: 55%;

}  

`

const Nav = styled.div`
color: #ff0000;

max-width: 100%;
display: flex;
flex-direction: column;
justify-content: center;
display: flex;
    align-items: center;

`
const NavItem = styled.h1`

text-align: center;
font-size: 25px !important;
margin-top: 2%;
margin-bottom: 2%;
-webkit-text-stroke: .1px black;`



    export default Home;

    