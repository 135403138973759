import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import GlobalStyles from './components/GlobalStyles';
import Home from './components/Home';
import About from './components/About';
import Shop from './components/Shop';
import Hypnotherapy from './components/Hypnotherapy';
import Group from './components/Group';




// App component with Router
const App = () => {
  return (
    <Router>
  <GlobalStyles/>
  

      <div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/self-hypnosis" element={<Shop/>} />
          <Route path="/hypnotherapy" element={<Hypnotherapy/>} />
          <Route path="/group-hypnosis" element={<Group/>} />



        </Routes>
      </div>
    </Router>
  );
};

export default App;
