import React, { useEffect } from 'react';

const BookingButton = () => {
  useEffect(() => {
    // Create script element
    const script = document.createElement('script');
    script.id = 'setmore_script';
    script.type = 'text/javascript';
    script.src = 'https://storage.googleapis.com/fullintegration-live/webComponentAppListing/Container/setmoreIframeLive.js';
    script.async = true;

    // Append script to body
    document.body.appendChild(script);

    // Cleanup script when component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div>
      <a
        style={{ float: 'none' }}
        id="Setmore_button_iframe"
        href="https://booking.setmore.com/scheduleappointment/c4107343-75f9-49b6-b7b3-2f756781e83f"
      >
        <img
          border="none"
          src="https://assets.setmore.com/setmore/images/2.0/Settings/book-now-black.svg"
          alt="Click here to book the appointment using setmore"
        />
      </a>
    </div>
  );
};

export default BookingButton;
