import React from 'react';
import Reset from './products/Reset';
import styled from 'styled-components';
import Membership from './products/Membership';
import Menu from './Menu';
import Footer from './Footer';
import MindMakeover from './products/MindMakeover';
import Confidence from './products/Confidence';
import Morning from './products/Morning';
import Optimism from './products/Optimism';
import Navbar from './Navbar';
import DesktopMenu from './DesktopMenu';
import GumroadProducts from './GumroadProducts';


const Shop= () => {

    return (
        <>

      
<Navbar/>
<DeskWrap>

<DesktopMenu/>
        {/* <Grid>
<Item><Reset/></Item>
<Item><Membership/></Item>
<Item><MindMakeover/></Item>
<Item><Confidence/></Item>
<Item><Morning/></Item>
<Item><Optimism/></Item>
</Grid> */}
<GumroadProducts/>
</DeskWrap>

<Footer/>

</>
    )
}
const DeskWrap = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;

    @media (min-width: 1000px) {
flex-direction: row;

}
`
const Grid = styled.div`

display:flex;
flex-direction: column;


    
@media (min-width: 500px) {
    display: flex;
    flex-direction: row;
    width:100%;
    flex-wrap: wrap;
}

@media (min-width: 1000px) {

}  `

const Item = styled.div`
width: 100%;

@media (min-width: 500px) {
   width: 50%;
}

@media (min-width: 1000px) {
   width: 30%;
}
`

export default Shop;