import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const GumroadProducts = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch('https://api.gumroad.com/v2/products', {
          headers: {
            Authorization: `Bearer SwX0283fDBybIXxIvPgqXxyIz7E3matNjaE-YoQHtoM`
          }
        });

        if (!response.ok) {
          throw new Error('Failed to fetch products');
        }

        const data = await response.json();
        setProducts(data.products);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, []);

  // Helper function to remove HTML tags
  const stripHtml = (html) => {
    const div = document.createElement('div');
    div.innerHTML = html;
    return div.textContent || div.innerText || "";
  };

  // Helper function to format price in Euros
  const formatPriceInEuros = (priceInCents) => {
    const euroPrice = priceInCents / 100; // Convert cents to euros
    return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(euroPrice);
  };

  if (loading) {
    return <p>Loading products...</p>;
  }

  if (error) {
    return <p>Error fetching products: {error}</p>;
  }

  return (
    <div>
    
      <Grid>
     
        {products.map((product) => (
          <Item key={product.id}>
            {/* <h3>{product.name}</h3> */}
            {/* <p>{stripHtml(product.description)}</p> */}
            <a href={product.short_url} target="_blank" rel="noopener noreferrer">
            {product.preview_url ? (
                <Img src={product.preview_url} alt={product.name} style={{ width: '100%', maxWidth: '275px' }} />
            ) : (
                <p>No image available</p>
            )}
            <Price> {formatPriceInEuros(product.price)}</Price>
            </a>
          </Item>
        ))}
        </Grid>
   
    </div>
  );
};

const Price = styled.p`
      font-family: "Agrandir-Regular", sans-serif;
    font-weight: 800;
    font-style: normal;
`

const Img = styled.img`
border-radius: 1rem;
 box-shadow: 0 4px 8px rgba(0, 0, 0, 0.7);
`
const Item = styled.li`
list-style: none;
margin: 1%;
display: flex;
    justify-content: center;

`
const Grid = styled.div`

display:flex;
flex-direction: column;


    
@media (min-width: 500px) {
    display: flex;
    flex-direction: row;
    width:100%;
    flex-wrap: wrap;
    justify-content: center;
}

@media (min-width: 1000px) {

}  `

export default GumroadProducts;
