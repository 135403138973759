import React from 'react';

const GumroadFollowForm = () => {
  const formHtml = `
    <style>
      #gumroad-follow-form-embed {
        margin: 0px;
        padding: 0px;
        box-sizing: border-box;
        min-width: 0px;
        max-width: 100%;
        vertical-align: bottom;
        background-clip: padding-box;
        scrollbar-color: rgb(0 0 0/0.5) rgb(0 0 0/0.1);
        display: grid;
        grid-auto-flow: column;
        gap: 0.75rem;
        grid-template-columns: 1fr;
        grid-auto-columns: max-content;
        align-items: center;
      }
      #gumroad-follow-form-embed-button {
        margin: 0px;
        padding: 0px;
        box-sizing: border-box;
        min-width: 0px;
        max-width: 100%;
        vertical-align: bottom;
        background-clip: padding-box;
        scrollbar-color: rgb(0 0 0/0.5) rgb(0 0 0/0.1);
        background: rgba(0, 0, 0, 0);
        font-size: 1rem;
        line-height: 1.5;
        padding: 0.75rem 1rem;
        border: solid .0625rem rgb(0 0 0/1);
        color: currentcolor;
        border-radius: 0.25rem;
        font-family: "Mabry Pro", Avenir, Montserrat, Corbel, "URW Gothic", source-sans-pro, sans-serif;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        gap: 0.5rem;
        cursor: pointer;
        text-decoration: none;
        transition-timing-function: ease-out;
        transition-duration: 0.14s;
        transition-property: transform;
        background-color: rgb(0 0 0);
        color: rgb(255 255 255);
      }
      #gumroad-follow-form-embed-button:hover {
        transform: translate(-0.25rem, -0.25rem);
        box-shadow: .25rem .25rem 0rem rgb(0 0 0);
        background-color: rgb(255 144 232);
        color: rgb(0 0 0);
      }
      #gumroad-follow-form-embed-input {
        margin: 0px;
        padding: 0px;
        box-sizing: border-box;
        min-width: 0px;
        max-width: 100%;
        vertical-align: bottom;
        background-clip: padding-box;
        scrollbar-color: rgb(0 0 0/0.5) rgb(0 0 0/0.1);
        font-family: "Mabry Pro", Avenir, Montserrat, Corbel, "URW Gothic", source-sans-pro, sans-serif;
        padding: 0.75rem 1rem;
        font-size: 1rem;
        line-height: 1.5;
        border: solid .0625rem rgb(0 0 0/1);
        border-radius: 0.25rem;
        display: block;
        width: 100%;
        background-color: rgb(255 255 255);
        color: rgb(0 0 0);
      }
      #gumroad-follow-form-embed-input:disabled {
        cursor: not-allowed;
        opacity: 0.3;
      }
      #gumroad-follow-form-embed-input::placeholder {
        color: rgb(0 0 0/0.5);
      }
      #gumroad-follow-form-embed-input:focus-within {
        outline: .125rem solid rgb(255 144 232);
      }
      #gumroad-follow-form-embed-input:read-only {
        background-color: #f4f4f0;
      }
    </style>
    <form class="input-with-button" action="https://app.gumroad.com/follow_from_embed_form" method="post" id="gumroad-follow-form-embed">
      <input type="hidden" name="seller_id" value="6165714248368"/>
      <input id="gumroad-follow-form-embed-input" type="email" placeholder="Your email address" name="email" value=""/>
      <button class="primary" type="submit" id="gumroad-follow-form-embed-button">Follow</button>
    </form>
  `;

  return <div dangerouslySetInnerHTML={{ __html: formHtml }} />;
};

export default GumroadFollowForm;
