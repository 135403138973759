import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Menu = () => {

return (
<Nav>

<ul>
            <li>
              <Link to="/about"><NavItem>ABOUT</NavItem></Link>
            </li>
            <li>
              <Link to="/self-hypnosis"><NavItem>SELF HYPNOSIS</NavItem></Link>
            </li>
            <li>
              <Link to="/hypnotherapy"><NavItem>HYPNOTHERAPY</NavItem></Link>
            </li>
            <li>
              <Link to="/group=hypnosis"><NavItem>GROUP HYPNOSIS</NavItem></Link>
            </li>
          </ul>
</Nav>
);

}

const Nav = styled.div`
color: #ff0000;
max-width: 100%;
display: flex;
flex-direction: column;
justify-content: center;
display: flex;
align-items: center;
font-family: "Agrandir-Regular", sans-serif;



    @media (min-width: 1000px) {


display: none;

}  
`
const NavItem = styled.h1`
color: #ff0000;
text-align: center;
font-size: 30px !important;
margin-top: 2%;
margin-bottom: 2%;
-webkit-text-stroke: .5px black;

@media (min-width: 500px) {
font-size: 40px !important;

}



// Link = styled.link`
// text-decoration: none;
// `

export default Menu;